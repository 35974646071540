import React, { useEffect, useState } from 'react'
import * as s from "./main.module.css"

// import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";

import "animate.css/animate.min.css";

import { withpath } from '../utils/index.js'

import Art1 from '../assets/art1.svg';
import Graph1 from '../assets/graph1.svg';
import Graph1mob from '../assets/graph1-mob.svg';
import Graph2 from '../assets/graph2.svg';
import Graph2mob from '../assets/graph2-mob.svg';
import Graph3 from '../assets/graph3.svg';
import Graph3mob from '../assets/graph3-mob.svg';

import AudioPlayer from './audio-player';

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  const mainduration = 1.3
  const shortduration = .8

  const [audioLoaded, setAudioLoaded] = useState(false);

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {

    let art1img = document.querySelectorAll(`#art1img`);
		gsap.killTweensOf([art1img]);
    gsap.set(art1img, {opacity: 0, scale:.4});

    let art2img = document.querySelectorAll(`#art2img`);
    let art2txt = document.querySelectorAll(`#art2txt`);
    gsap.killTweensOf([art2img, art2txt]);
		gsap.set([art2img], {opacity: 0, scale:.4});
    gsap.set(art2txt, {opacity: 0, translateY: -20});

    let art3img = document.querySelectorAll(`#art3img`);
		gsap.killTweensOf([art3img]);
    gsap.set([art3img], {opacity: 0, scale:.7});

    let art4img = document.querySelectorAll(`#art4img`);
		gsap.killTweensOf([art4img]);
    gsap.set([art4img], {opacity: 0, scale:.7});

    let art5img = document.querySelectorAll(`#art5img`);
    let art51 = document.querySelectorAll(`#art51`);
		gsap.killTweensOf([art5img, art51]);
    gsap.set([art5img, art51], {opacity: 0, scale:.7});

    let art6img = document.querySelectorAll(`#art6img`);
		gsap.killTweensOf([art6img]);
    gsap.set([art6img], {opacity: 0, scale:.7});

    let art7img = document.querySelectorAll(`#art7img`);
		gsap.killTweensOf([art7img]);
    gsap.set([art7img], {opacity: 0, scale:.7});

    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);
		gsap.killTweensOf([art8img, art81]);
    gsap.set([art8img, art81], {opacity: 0, scale:.7});

    let art9img = document.querySelectorAll(`#art9img`);
    let art91 = document.querySelectorAll(`#art91`);
		gsap.killTweensOf([art9img, art91]);
    gsap.set([art8img, art91], {opacity: 0, scale:.7});

    let g1quality1 = document.querySelectorAll(`#g1-quality1`);
    let g1quality2 = document.querySelectorAll(`#g1-quality2`);
    let g1quality3 = document.querySelectorAll(`#g1-quality3`);
    let g1quality4 = document.querySelectorAll(`#g1-quality4`);
    let g1legend = document.querySelectorAll(`#g1-legend`);
    let g1xaxis1 = document.querySelectorAll(`#g1-xaxis1`);
    let g1xaxis2 = document.querySelectorAll(`#g1-xaxis2`);
    let g1vertlines = document.querySelectorAll(`#g1-vert-lines`);
    let g1block1 = document.querySelectorAll(`#g1-block1`);
    let g1block2 = document.querySelectorAll(`#g1-block2`);
    let g1block3 = document.querySelectorAll(`#g1-block3`);
    let g1block4 = document.querySelectorAll(`#g1-block4`);
    let g1block5 = document.querySelectorAll(`#g1-block5`);
    let g1block6 = document.querySelectorAll(`#g1-block6`);
    let g1block7 = document.querySelectorAll(`#g1-block7`);
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1txt5 = document.querySelectorAll(`#g1-txt5`);
    let g1txt6 = document.querySelectorAll(`#g1-txt6`);
    let g1txt7 = document.querySelectorAll(`#g1-txt7`);
    let g1trend = document.querySelectorAll(`#g1-trend-line`);
    let g1conclusion = document.querySelectorAll(`#g1-conclusion`);

    gsap.killTweensOf([g1quality1, g1quality2, g1quality3, g1quality4, g1legend, g1xaxis1, g1xaxis2, g1vertlines, g1block1, g1block2, g1block3, g1block4, g1block5, g1block6, g1block7, g1txt1, g1txt2, g1txt3, g1txt4, g1txt5, g1txt6, g1txt7, g1trend, g1conclusion]);
    gsap.set([g1block1, g1block2, g1block3, g1block4, g1block5, g1block6, g1block7, g1trend], {opacity: 0, scaleY:0});
    gsap.set([g1quality1, g1quality2, g1quality3, g1quality4, g1legend, g1xaxis1, g1xaxis2, g1vertlines, g1txt1, g1txt2, g1txt3, g1txt4, g1txt5, g1txt6, g1txt7, g1conclusion], {opacity: 0, translateY:8});


    let g1quality1m = document.querySelectorAll(`#g1-quality1m`);
    let g1quality2m = document.querySelectorAll(`#g1-quality2m`);
    let g1quality3m = document.querySelectorAll(`#g1-quality3m`);
    let g1quality4m = document.querySelectorAll(`#g1-quality4m`);
    let g1legendm = document.querySelectorAll(`#g1-legendm`);
    let g1xaxis1m = document.querySelectorAll(`#g1-xaxis1m`);
    let g1xaxis2m = document.querySelectorAll(`#g1-xaxis2m`);
    let g1vertlinesm = document.querySelectorAll(`#g1-vert-linesm`);
    let g1block1m = document.querySelectorAll(`#g1-block1m`);
    let g1block2m = document.querySelectorAll(`#g1-block2m`);
    let g1block3m = document.querySelectorAll(`#g1-block3m`);
    let g1block4m = document.querySelectorAll(`#g1-block4m`);
    let g1block5m = document.querySelectorAll(`#g1-block5m`);
    let g1block6m = document.querySelectorAll(`#g1-block6m`);
    let g1block7m = document.querySelectorAll(`#g1-block7m`);
    let g1txt1m = document.querySelectorAll(`#g1-txt1m`);
    let g1txt2m = document.querySelectorAll(`#g1-txt2m`);
    let g1txt3m = document.querySelectorAll(`#g1-txt3m`);
    let g1txt4m = document.querySelectorAll(`#g1-txt4m`);
    let g1txt5m = document.querySelectorAll(`#g1-txt5m`);
    let g1txt6m = document.querySelectorAll(`#g1-txt6m`);
    let g1txt7m = document.querySelectorAll(`#g1-txt7m`);
    let g1trendm = document.querySelectorAll(`#g1-trend-linem`);
    let g1conclusionm = document.querySelectorAll(`#g1-conclusionm`);

    gsap.killTweensOf([g1quality1m, g1quality2m, g1quality3m, g1quality4m, g1legendm, g1xaxis1m, g1xaxis2m, g1vertlinesm, g1block1m, g1block2m, g1block3m, g1block4m, g1block5m, g1block6m, g1block7m, g1txt1m, g1txt2m, g1txt3m, g1txt4m, g1txt5m, g1txt6m, g1txt7m, g1trendm, g1conclusionm]);
    gsap.set([g1block1m, g1block2m, g1block3m, g1block4m, g1block5m, g1block6m, g1block7m, g1trendm], {opacity: 0, scaleY:0});
    gsap.set([g1quality1m, g1quality2m, g1quality3m, g1quality4m, g1legendm, g1xaxis1m, g1xaxis2m, g1vertlinesm, g1txt1m, g1txt2m, g1txt3m, g1txt4m, g1txt5m, g1txt6m, g1txt7m, g1conclusionm], {opacity: 0, translateY:8});
    


    let g2fill = document.querySelectorAll(`#g2-fill`);
    let g2outline = document.querySelectorAll(`#g2-outline`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2txt4 = document.querySelectorAll(`#g2-txt4`);

    gsap.killTweensOf([g2fill, g2outline, g2txt1, g2txt2, g2txt3, g2txt4]);
    gsap.set([g2fill, g2outline], {opacity: 0, scaleY:0});
    gsap.set([g2txt1, g2txt2, g2txt3, g2txt4], {opacity: 0, translateY:8});


    let g2fillm = document.querySelectorAll(`#g2-fill-mob`);
    let g2outlinem = document.querySelectorAll(`#g2-outline-mob`);
    let g2txt1m = document.querySelectorAll(`#g2-txt-mob1`);
    let g2txt2m = document.querySelectorAll(`#g2-txt-mob2`);
    let g2txt3m = document.querySelectorAll(`#g2-txt-mob3`);
    let g2txt4m = document.querySelectorAll(`#g2-txt-mob4`);

    gsap.killTweensOf([g2fillm, g2outlinem, g2txt1m, g2txt2m, g2txt3m, g2txt4m]);
    gsap.set([g2fillm, g2outlinem], {opacity: 0, scaleY:0});
    gsap.set([g2txt1m, g2txt2m, g2txt3m, g2txt4m], {opacity: 0, translateY:8});


    let g3row1 = document.querySelectorAll(`#squares1`);
    let g3row2 = document.querySelectorAll(`#squares2`);
    let g3row3 = document.querySelectorAll(`#squares3`);
    let g3row4 = document.querySelectorAll(`#squares4`);
    let g3row5 = document.querySelectorAll(`#squares5`);
    let g3row6 = document.querySelectorAll(`#squares6`);
    let g3row7 = document.querySelectorAll(`#squares7`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3txt4 = document.querySelectorAll(`#g3-txt4`);
    let g3txt5 = document.querySelectorAll(`#g3-txt5`);
    let g3txt6 = document.querySelectorAll(`#g3-txt6`);
    let g3txt7 = document.querySelectorAll(`#g3-txt7`);

    gsap.killTweensOf([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7, g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7]);
    gsap.set([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7], {opacity: 0, translateY:10});
    gsap.set([g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7], {opacity: 0, translateY:8});


    let g3row1m = document.querySelectorAll(`#g3-row-mob1`);
    let g3row2m = document.querySelectorAll(`#g3-row-mob2`);
    let g3row3m = document.querySelectorAll(`#g3-row-mob3`);
    let g3row4m = document.querySelectorAll(`#g3-row-mob4`);
    let g3row5m = document.querySelectorAll(`#g3-row-mob5`);
    let g3row6m = document.querySelectorAll(`#g3-row-mob6`);
    let g3row7m = document.querySelectorAll(`#g3-row-mob7`);
    let g3txt1m = document.querySelectorAll(`#g3-txt-mob1`);
    let g3txt2m = document.querySelectorAll(`#g3-txt-mob2`);
    let g3txt3m = document.querySelectorAll(`#g3-txt-mob3`);
    let g3txt4m = document.querySelectorAll(`#g3-txt-mob4`);
    let g3txt5m = document.querySelectorAll(`#g3-txt-mob5`);
    let g3txt6m = document.querySelectorAll(`#g3-txt-mob6`);
    let g3txt7m = document.querySelectorAll(`#g3-txt-mob7`);

    gsap.killTweensOf([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m, g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m]);
    gsap.set([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m], {opacity: 0, translateY:10});
    gsap.set([g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m], {opacity: 0, translateY:8});


  },[])
  

  /* function enterP(){
		
	}

	function leaveP(){
		let person1img = document.querySelectorAll(`#person1-img`);
    let person1name = document.querySelectorAll(`#person1-name`);
    let person2img = document.querySelectorAll(`#person2-img`);
    let person2name = document.querySelectorAll(`#person2-name`);

		gsap.killTweensOf([person1img,person1name,person2img,person2name]);

    let tl = gsap.timeline({ paused: false });
      tl.to([person1img,person1name,person2img,person2name], {opacity: 0, translateY: -20, duration: .4, stagger: .07, ease: `power1.out` }, 0.0);
	} */


	function enter1(){

    let art1img = document.querySelectorAll(`#art1img`);

		gsap.killTweensOf([art1img]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art1img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
	}

	function leave1(){

    let art1img = document.querySelectorAll(`#art1img`);

		gsap.killTweensOf([art1img]);

		let tl = gsap.timeline({ paused: false });
      tl.to([art1img], {opacity: 0, scale: .7, duration: .2, ease: `power1.out` });

	}


	function enter2(){

    let art2img = document.querySelectorAll(`#art2img`);
    let art2txt = document.querySelectorAll(`#art2txt`);

    gsap.killTweensOf([art2img, art2txt]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art2img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out`}, 0.0)
      tl.to(art2txt, {opacity: 1, translateY: 0, duration: shortduration, ease: `power2.out`}, 0.3);
	}

	function leave2(){

		let art2img = document.querySelectorAll(`#art2img`);
    let art2txt = document.querySelectorAll(`#art2txt`);

    gsap.killTweensOf([art2img, art2txt]);

		let tl = gsap.timeline({ paused: false });
			tl.to([art2img], {opacity: 0, scale: .4, duration: .2, stagger: .05, ease: `power1.out` }, 0.0);
      tl.to(art2txt, {opacity: 0, translateY: -20, duration: .2, ease: `power1.out` }, 0.0);

	}


	function enter3(){

		let art3img = document.querySelectorAll(`#art3img`);
		gsap.killTweensOf([art3img]);

    
		let tl = gsap.timeline({ paused: false });
			tl.to(art3img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` }, 0.0);

	}

	function leave3(){

		let art3img = document.querySelectorAll(`#art3img`);
		gsap.killTweensOf([art3img]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art3img], {opacity: 0, scale: .7, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);

  }


  function enter4(){

    let art4img = document.querySelectorAll(`#art4img`);

		gsap.killTweensOf([art4img]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art4img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
	}

	function leave4(){

    let art4img = document.querySelectorAll(`#art4img`);
		gsap.killTweensOf([art4img]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art4img], {opacity: 0, scale: .7, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);

  }


  function enter5(){

    let art5img = document.querySelectorAll(`#art5img`);
    let art51 = document.querySelectorAll(`#art51`);

		gsap.killTweensOf([art5img, art51]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art5img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art51, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .35);
  }
  
  function leave5(){

		let art5img = document.querySelectorAll(`#art5img`);
    let art51 = document.querySelectorAll(`#art51`);

		gsap.killTweensOf([art5img, art51]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to([art5img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
      tl.to([art51], {opacity: 0, scale: .4, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter6(){

    let art6img = document.querySelectorAll(`#art6img`);

		gsap.killTweensOf([art6img]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art6img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });

  }
  
  function leave6(){

		let art6img = document.querySelectorAll(`#art6img`);
		  gsap.killTweensOf([art6img]);

    let tl = gsap.timeline({ paused: false });
      tl.to([art6img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);

  }

  function enter7(){

    let art7img = document.querySelectorAll(`#art7img`);

		gsap.killTweensOf([art7img]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to(art7img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });

  }
  
  function leave7(){

    let art7img = document.querySelectorAll(`#art7img`);

		gsap.killTweensOf([art7img]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to([art7img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);

  }

  function enter8(){

    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);

    gsap.killTweensOf([art8img, art81]);

    let tl = gsap.timeline({ paused: false });
      tl.to(art8img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art81, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .1);

  }
  
  function leave8(){
    
    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);

    gsap.killTweensOf([art8img, art81]);
    
		let tl = gsap.timeline({ paused: false });
    tl.to([art8img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art81], {opacity: 0, scale: .4, stagger: .08, ease: `power1.out` }, 0.0);

  }


  function enter9(){

    let art9img = document.querySelectorAll(`#art9img`);
    let art91 = document.querySelectorAll(`#art91`);

		gsap.killTweensOf([art9img, art91]);

    let tl = gsap.timeline({ paused: false });
      tl.to(art9img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art91, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .1);

  }
  
  function leave9(){

    let art9img = document.querySelectorAll(`#art9img`);
    let art91 = document.querySelectorAll(`#art91`);

		gsap.killTweensOf([art9img, art91]);
    
		let tl = gsap.timeline({ paused: false });
      tl.to([art9img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
      tl.to([art91], {opacity: 0, scale: .4, stagger: .08, ease: `power1.out` }, 0.0);

  }


  function enterg1(){

    let g1quality1 = document.querySelectorAll(`#g1-quality1`);
    let g1quality2 = document.querySelectorAll(`#g1-quality2`);
    let g1quality3 = document.querySelectorAll(`#g1-quality3`);
    let g1quality4 = document.querySelectorAll(`#g1-quality4`);
    let g1legend = document.querySelectorAll(`#g1-legend`);
    let g1xaxis1 = document.querySelectorAll(`#g1-xaxis1`);
    let g1xaxis2 = document.querySelectorAll(`#g1-xaxis2`);
    let g1vertlines = document.querySelectorAll(`#g1-vert-lines`);
    let g1block1 = document.querySelectorAll(`#g1-block1`);
    let g1block2 = document.querySelectorAll(`#g1-block2`);
    let g1block3 = document.querySelectorAll(`#g1-block3`);
    let g1block4 = document.querySelectorAll(`#g1-block4`);
    let g1block5 = document.querySelectorAll(`#g1-block5`);
    let g1block6 = document.querySelectorAll(`#g1-block6`);
    let g1block7 = document.querySelectorAll(`#g1-block7`);
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1txt5 = document.querySelectorAll(`#g1-txt5`);
    let g1txt6 = document.querySelectorAll(`#g1-txt6`);
    let g1txt7 = document.querySelectorAll(`#g1-txt7`);
    let g1trend = document.querySelectorAll(`#g1-trend-line`);
    let g1conclusion = document.querySelectorAll(`#g1-conclusion`);

    gsap.killTweensOf([g1quality1, g1quality2, g1quality3, g1quality4, g1legend, g1xaxis1, g1xaxis2, g1vertlines, g1block1, g1block2, g1block3, g1block4, g1block5, g1block6, g1block7, g1txt1, g1txt2, g1txt3, g1txt4, g1txt5, g1txt6, g1txt7, g1trend, g1conclusion]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g1quality1, g1quality2, g1quality3, g1quality4], {opacity: 1, translateY: 0, stagger: .2, ease: `power1.out` }, 0.0);
      tl.to([g1vertlines, g1xaxis1, g1xaxis2], {opacity: 1, translateY: 0, stagger: .2, ease: `power1.out` }, 0.3);
      tl.to(g1txt1, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.4);
      tl.to(g1block1, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.4);
      tl.to(g1txt2, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.6);
      tl.to(g1block2, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.6);
      tl.to(g1txt3, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.8);
      tl.to(g1block3, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.8);
      tl.to(g1txt4, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.0);
      tl.to(g1block4, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.0);
      tl.to(g1txt5, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.2);
      tl.to(g1block5, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.2);
      tl.to(g1txt6, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.4);
      tl.to(g1block6, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.4);
      tl.to(g1txt7, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.6);
      tl.to(g1block7, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.6);
      tl.to(g1trend, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.8);
      tl.to(g1txt7, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.0);
      tl.to(g1legend, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.1);
      tl.to(g1conclusion, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.8);

  }
  
  function leaveg1(){

    let g1quality1 = document.querySelectorAll(`#g1-quality1`);
    let g1quality2 = document.querySelectorAll(`#g1-quality2`);
    let g1quality3 = document.querySelectorAll(`#g1-quality3`);
    let g1quality4 = document.querySelectorAll(`#g1-quality4`);
    let g1legend = document.querySelectorAll(`#g1-legend`);
    let g1xaxis1 = document.querySelectorAll(`#g1-xaxis1`);
    let g1xaxis2 = document.querySelectorAll(`#g1-xaxis2`);
    let g1vertlines = document.querySelectorAll(`#g1-vert-lines`);
    let g1block1 = document.querySelectorAll(`#g1-block1`);
    let g1block2 = document.querySelectorAll(`#g1-block2`);
    let g1block3 = document.querySelectorAll(`#g1-block3`);
    let g1block4 = document.querySelectorAll(`#g1-block4`);
    let g1block5 = document.querySelectorAll(`#g1-block5`);
    let g1block6 = document.querySelectorAll(`#g1-block6`);
    let g1block7 = document.querySelectorAll(`#g1-block7`);
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1txt5 = document.querySelectorAll(`#g1-txt5`);
    let g1txt6 = document.querySelectorAll(`#g1-txt6`);
    let g1txt7 = document.querySelectorAll(`#g1-txt7`);
    let g1trend = document.querySelectorAll(`#g1-trend-line`);
    let g1conclusion = document.querySelectorAll(`#g1-conclusion`);

    gsap.killTweensOf([g1quality1, g1quality2, g1quality3, g1quality4, g1legend, g1xaxis1, g1xaxis2, g1vertlines, g1block1, g1block2, g1block3, g1block4, g1block5, g1block6, g1block7, g1txt1, g1txt2, g1txt3, g1txt4, g1txt5, g1txt6, g1txt7, g1trend]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g1block1, g1block2, g1block3, g1block4, g1block5, g1block6, g1block7, g1trend], {opacity: 0, scaleY: 0, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
      tl.to([g1quality1, g1quality2, g1quality3, g1quality4, g1legend, g1xaxis1, g1xaxis2, g1vertlines, g1conclusion, g1txt1, g1txt2, g1txt3, g1txt4, g1txt5, g1txt6, g1txt7], {opacity: 0, translateY: 6, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enterg1mob(){

    let g1quality1m = document.querySelectorAll(`#g1-quality1m`);
    let g1quality2m = document.querySelectorAll(`#g1-quality2m`);
    let g1quality3m = document.querySelectorAll(`#g1-quality3m`);
    let g1quality4m = document.querySelectorAll(`#g1-quality4m`);
    let g1legendm = document.querySelectorAll(`#g1-legendm`);
    let g1xaxis1m = document.querySelectorAll(`#g1-xaxis1m`);
    let g1xaxis2m = document.querySelectorAll(`#g1-xaxis2m`);
    let g1vertlinesm = document.querySelectorAll(`#g1-vert-linesm`);
    let g1block1m = document.querySelectorAll(`#g1-block1m`);
    let g1block2m = document.querySelectorAll(`#g1-block2m`);
    let g1block3m = document.querySelectorAll(`#g1-block3m`);
    let g1block4m = document.querySelectorAll(`#g1-block4m`);
    let g1block5m = document.querySelectorAll(`#g1-block5m`);
    let g1block6m = document.querySelectorAll(`#g1-block6m`);
    let g1block7m = document.querySelectorAll(`#g1-block7m`);
    let g1txt1m = document.querySelectorAll(`#g1-txt1m`);
    let g1txt2m = document.querySelectorAll(`#g1-txt2m`);
    let g1txt3m = document.querySelectorAll(`#g1-txt3m`);
    let g1txt4m = document.querySelectorAll(`#g1-txt4m`);
    let g1txt5m = document.querySelectorAll(`#g1-txt5m`);
    let g1txt6m = document.querySelectorAll(`#g1-txt6m`);
    let g1txt7m = document.querySelectorAll(`#g1-txt7m`);
    let g1trendm = document.querySelectorAll(`#g1-trend-linem`);
    let g1conclusionm = document.querySelectorAll(`#g1-conclusionm`);

    gsap.killTweensOf([g1quality1m, g1quality2m, g1quality3m, g1quality4m, g1legendm, g1xaxis1m, g1xaxis2m, g1vertlinesm, g1block1m, g1block2m, g1block3m, g1block4m, g1block5m, g1block6m, g1block7m, g1txt1m, g1txt2m, g1txt3m, g1txt4m, g1txt5m, g1txt6m, g1txt7m, g1trendm, g1conclusionm]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g1quality1m, g1quality2m, g1quality3m, g1quality4m], {opacity: 1, translateY: 0, stagger: .2, ease: `power1.out` }, 0.0);
      tl.to([g1vertlinesm, g1xaxis1m, g1xaxis2m], {opacity: 1, translateY: 0, stagger: .2, ease: `power1.out` }, 0.3);
      tl.to(g1txt1m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.4);
      tl.to(g1block1m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.4);
      tl.to(g1txt2m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.6);
      tl.to(g1block2m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.6);
      tl.to(g1txt3m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.8);
      tl.to(g1block3m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 0.8);
      tl.to(g1txt4m, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.0);
      tl.to(g1block4m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.0);
      tl.to(g1txt5m, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.2);
      tl.to(g1block5m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.2);
      tl.to(g1txt6m, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.4);
      tl.to(g1block6m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.4);
      tl.to(g1txt7m, {opacity: 1, translateY: 0, ease: `power1.out` }, 1.6);
      tl.to(g1block7m, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.6);
      tl.to(g1trendm, {opacity: 1, scaleY: 1, transformOrigin: 'bottom center', ease: `power1.out` }, 1.8);
      tl.to(g1txt7m, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.0);
      tl.to(g1legendm, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.1);
      tl.to(g1conclusionm, {opacity: 1, translateY: 0, ease: `power1.out` }, 2.8);

  }
  
  function leaveg1mob(){

    let g1quality1m = document.querySelectorAll(`#g1-quality1m`);
    let g1quality2m = document.querySelectorAll(`#g1-quality2m`);
    let g1quality3m = document.querySelectorAll(`#g1-quality3m`);
    let g1quality4m = document.querySelectorAll(`#g1-quality4m`);
    let g1legendm = document.querySelectorAll(`#g1-legendm`);
    let g1xaxis1m = document.querySelectorAll(`#g1-xaxis1m`);
    let g1xaxis2m = document.querySelectorAll(`#g1-xaxis2m`);
    let g1vertlinesm = document.querySelectorAll(`#g1-vert-linesm`);
    let g1block1m = document.querySelectorAll(`#g1-block1m`);
    let g1block2m = document.querySelectorAll(`#g1-block2m`);
    let g1block3m = document.querySelectorAll(`#g1-block3m`);
    let g1block4m = document.querySelectorAll(`#g1-block4m`);
    let g1block5m = document.querySelectorAll(`#g1-block5m`);
    let g1block6m = document.querySelectorAll(`#g1-block6m`);
    let g1block7m = document.querySelectorAll(`#g1-block7m`);
    let g1txt1m = document.querySelectorAll(`#g1-txt1m`);
    let g1txt2m = document.querySelectorAll(`#g1-txt2m`);
    let g1txt3m = document.querySelectorAll(`#g1-txt3m`);
    let g1txt4m = document.querySelectorAll(`#g1-txt4m`);
    let g1txt5m = document.querySelectorAll(`#g1-txt5m`);
    let g1txt6m = document.querySelectorAll(`#g1-txt6m`);
    let g1txt7m = document.querySelectorAll(`#g1-txt7m`);
    let g1trendm = document.querySelectorAll(`#g1-trend-linem`);
    let g1conclusionm = document.querySelectorAll(`#g1-conclusionm`);

    gsap.killTweensOf([g1quality1m, g1quality2m, g1quality3m, g1quality4m, g1legendm, g1xaxis1m, g1xaxis2m, g1vertlinesm, g1block1m, g1block2m, g1block3m, g1block4m, g1block5m, g1block6m, g1block7m, g1txt1m, g1txt2m, g1txt3m, g1txt4m, g1txt5m, g1txt6m, g1txt7m, g1trendm, g1conclusionm]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g1block1m, g1block2m, g1block3m, g1block4m, g1block5m, g1block6m, g1block7m, g1trendm], {opacity: 0, scaleY: 0, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
      tl.to([g1quality1m, g1quality2m, g1quality3m, g1quality4m, g1legendm, g1xaxis1m, g1xaxis2m, g1vertlinesm, g1conclusionm, g1txt1m, g1txt2m, g1txt3m, g1txt4m, g1txt5m, g1txt6m, g1txt7m], {opacity: 0, translateY: 6, stagger: .08, ease: `power1.out` }, 0.0);
    
  }


  function enterg2(){

    let g2fill = document.querySelectorAll(`#g2-fill`);
    let g2outline = document.querySelectorAll(`#g2-outline`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2txt4 = document.querySelectorAll(`#g2-txt4`);

    gsap.killTweensOf([g2fill, g2outline, g2txt1, g2txt2, g2txt3, g2txt4]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g2txt1, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.0);
      tl.to(g2txt2, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.1);
      tl.to(g2txt3, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.2);
      tl.to(g2txt4, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.3);
      tl.to(g2fill, {opacity: 1, scaleY: 1, transformOrigin: 'bottom', ease: `power1.out` }, 0.3);
      tl.to(g2outline, {opacity: 1, scaleY: 1, transformOrigin: 'bottom', ease: `power1.out` }, 0.3);

  }
  
  function leaveg2(){

    let g2fill = document.querySelectorAll(`#g2-fill`);
    let g2outline = document.querySelectorAll(`#g2-outline`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2txt4 = document.querySelectorAll(`#g2-txt4`);

    gsap.killTweensOf([g2fill, g2outline, g2txt1, g2txt2, g2txt3, g2txt4]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g2fill, g2outline], {opacity: 0, scaleY: 0, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
      tl.to([g2txt1, g2txt2, g2txt3, g2txt4], {opacity: 0, translateY: 8, stagger: .08, ease: `power1.out` }, 0.0);

  }

  function enterg2mob(){

    let g2fillm = document.querySelectorAll(`#g2-fill-mob`);
    let g2outlinem = document.querySelectorAll(`#g2-outline-mob`);
    let g2txt1m = document.querySelectorAll(`#g2-txt-mob1`);
    let g2txt2m = document.querySelectorAll(`#g2-txt-mob2`);
    let g2txt3m = document.querySelectorAll(`#g2-txt-mob3`);
    let g2txt4m = document.querySelectorAll(`#g2-txt-mob4`);

    gsap.killTweensOf([g2fillm, g2outlinem, g2txt1m, g2txt2m, g2txt3m, g2txt4m]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g2txt1m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.0);
      tl.to(g2txt2m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.1);
      tl.to(g2txt3m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.2);
      tl.to(g2txt4m, {opacity: 1, translateY: 0, ease: `power1.out` }, 0.3);
      tl.to(g2fillm, {opacity: 1, scaleY: 1, transformOrigin: 'bottom', ease: `power1.out` }, 0.3);
      tl.to(g2outlinem, {opacity: 1, scaleY: 1, transformOrigin: 'bottom', ease: `power1.out` }, 0.3);

  }
  
  function leaveg2mob(){

    let g2fillm = document.querySelectorAll(`#g2-fill-mob`);
    let g2outlinem = document.querySelectorAll(`#g2-outline-mob`);
    let g2txt1m = document.querySelectorAll(`#g2-txt-mob1`);
    let g2txt2m = document.querySelectorAll(`#g2-txt-mob2`);
    let g2txt3m = document.querySelectorAll(`#g2-txt-mob3`);
    let g2txt4m = document.querySelectorAll(`#g2-txt-mob4`);

    gsap.killTweensOf([g2fillm, g2outlinem, g2txt1m, g2txt2m, g2txt3m, g2txt4m]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g2fillm, g2outlinem], {opacity: 0, scaleY: 0, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
      tl.to([g2txt1m, g2txt2m, g2txt3m, g2txt4m], {opacity: 0, translateY: 8, stagger: .08, ease: `power1.out` }, 0.0);

  }


  function enterg3(){

    let g3row1 = document.querySelectorAll(`#squares1`);
    let g3row2 = document.querySelectorAll(`#squares2`);
    let g3row3 = document.querySelectorAll(`#squares3`);
    let g3row4 = document.querySelectorAll(`#squares4`);
    let g3row5 = document.querySelectorAll(`#squares5`);
    let g3row6 = document.querySelectorAll(`#squares6`);
    let g3row7 = document.querySelectorAll(`#squares7`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3txt4 = document.querySelectorAll(`#g3-txt4`);
    let g3txt5 = document.querySelectorAll(`#g3-txt5`);
    let g3txt6 = document.querySelectorAll(`#g3-txt6`);
    let g3txt7 = document.querySelectorAll(`#g3-txt7`);

    gsap.killTweensOf([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7, g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7], {opacity: 1, translateY: 0, stagger: 0.4, ease: `power1.out` }, 0.0);
      tl.to([g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7], {opacity: 1, translateY: 0, stagger: 0.4, ease: `power1.out` }, 0.0);

  }
  
  function leaveg3(){

    let g3row1 = document.querySelectorAll(`#squares1`);
    let g3row2 = document.querySelectorAll(`#squares2`);
    let g3row3 = document.querySelectorAll(`#squares3`);
    let g3row4 = document.querySelectorAll(`#squares4`);
    let g3row5 = document.querySelectorAll(`#squares5`);
    let g3row6 = document.querySelectorAll(`#squares6`);
    let g3row7 = document.querySelectorAll(`#squares7`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3txt4 = document.querySelectorAll(`#g3-txt4`);
    let g3txt5 = document.querySelectorAll(`#g3-txt5`);
    let g3txt6 = document.querySelectorAll(`#g3-txt6`);
    let g3txt7 = document.querySelectorAll(`#g3-txt7`);

    gsap.killTweensOf([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7, g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g3row1, g3row2, g3row3, g3row4, g3row5, g3row6, g3row7], {opacity: 0, translateY: 10, stagger: .08, ease: `power1.out` }, 0.0);
      tl.to([g3txt1, g3txt2, g3txt3, g3txt4, g3txt5, g3txt6, g3txt7], {opacity: 0, translateY: 8, stagger: .08, ease: `power1.out` }, 0.0);

  }

  function enterg3mob(){

    let g3row1m = document.querySelectorAll(`#g3-row-mob1`);
    let g3row2m = document.querySelectorAll(`#g3-row-mob2`);
    let g3row3m = document.querySelectorAll(`#g3-row-mob3`);
    let g3row4m = document.querySelectorAll(`#g3-row-mob4`);
    let g3row5m = document.querySelectorAll(`#g3-row-mob5`);
    let g3row6m = document.querySelectorAll(`#g3-row-mob6`);
    let g3row7m = document.querySelectorAll(`#g3-row-mob7`);
    let g3txt1m = document.querySelectorAll(`#g3-txt-mob1`);
    let g3txt2m = document.querySelectorAll(`#g3-txt-mob2`);
    let g3txt3m = document.querySelectorAll(`#g3-txt-mob3`);
    let g3txt4m = document.querySelectorAll(`#g3-txt-mob4`);
    let g3txt5m = document.querySelectorAll(`#g3-txt-mob5`);
    let g3txt6m = document.querySelectorAll(`#g3-txt-mob6`);
    let g3txt7m = document.querySelectorAll(`#g3-txt-mob7`);

    gsap.killTweensOf([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m, g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m]);

    let tl = gsap.timeline({ paused: false });
      tl.to([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m], {opacity: 1, translateY: 0, stagger: 0.4, ease: `power1.out` }, 0.0);
      tl.to([g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m], {opacity: 1, translateY: 0, stagger: 0.4, ease: `power1.out` }, 0.0);

  }
  
function leaveg3mob(){

  let g3row1m = document.querySelectorAll(`#g3-row-mob1`);
  let g3row2m = document.querySelectorAll(`#g3-row-mob2`);
  let g3row3m = document.querySelectorAll(`#g3-row-mob3`);
  let g3row4m = document.querySelectorAll(`#g3-row-mob4`);
  let g3row5m = document.querySelectorAll(`#g3-row-mob5`);
  let g3row6m = document.querySelectorAll(`#g3-row-mob6`);
  let g3row7m = document.querySelectorAll(`#g3-row-mob7`);
  let g3txt1m = document.querySelectorAll(`#g3-txt-mob1`);
  let g3txt2m = document.querySelectorAll(`#g3-txt-mob2`);
  let g3txt3m = document.querySelectorAll(`#g3-txt-mob3`);
  let g3txt4m = document.querySelectorAll(`#g3-txt-mob4`);
  let g3txt5m = document.querySelectorAll(`#g3-txt-mob5`);
  let g3txt6m = document.querySelectorAll(`#g3-txt-mob6`);
  let g3txt7m = document.querySelectorAll(`#g3-txt-mob7`);

  gsap.killTweensOf([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m, g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m]);

  let tl = gsap.timeline({ paused: false });
    tl.to([g3row1m, g3row2m, g3row3m, g3row4m, g3row5m, g3row6m, g3row7m], {opacity: 0, translateY: 10, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([g3txt1m, g3txt2m, g3txt3m, g3txt4m, g3txt5m, g3txt6m, g3txt7m], {opacity: 0, translateY: 8, stagger: .08, ease: `power1.out` }, 0.0);
  }


  const oTrack = (eventType, mediaName) => {
    document.body.dispatchEvent(new CustomEvent('oTracking.event', {
      detail: {
        category: 'cta',
        action: 'click',
        product: 'paid-post',
        app: 'infographic',
        url: document.URL,
        pageName: document.getElementsByTagName('h1').textContent,
        domPathTokens: [
          {
            'data-o-event': 'category:cta|action:click',
            'data-trackable': eventType,
            'href': document.URL,
            'text': mediaName
          }
        ]
      },
      bubbles: true
    }))
  }

  // Tracking script
  let runTracking = () => {
    try {

      let mediaElements = document.querySelectorAll('audio');
  
      let intervalRef;
      const intervalTime = [1, 25, 50, 75];
    
      mediaElements &&
        mediaElements.forEach((el, i) => {
          console.log(el);
          setAudioLoaded(true);

          el.addEventListener('play', (e) => {
            const duration = el.duration;
            const type = e.srcElement.localName;
            const finalArray = [];

            console.log('playing');
    
            try {
              global.AM_TrackEvent('Playing', 'Audio', el.getAttribute('data-name'), 0)
            } catch(exception) {
              console.error(exception);
            }

            oTrack('play', el.getAttribute('data-name'));
  
            startTimer(e.target, duration, type, finalArray, el.getAttribute('data-name'));

          })

          el.addEventListener('pause', (e) => {
            console.log(el.readyState);
            if (el.readyState === 4) {
              try {
                global.AM_TrackEvent('Paused', 'Audio', el.getAttribute('data-name'), 0)
              } catch(exception) {
                console.error(exception);
              }

              oTrack('pause', el.getAttribute('data-name'));
            }
          });
        });
      
      const startTimer = (media, duration, type, finalArray, name) => {
        console.log(media);
        clearInterval(intervalRef);
        intervalRef = setInterval(() => {
          const currentTime = Math.round((media.currentTime / duration) * 100);
          console.log(currentTime);
          if (
            intervalTime.includes(currentTime) &&
            !finalArray.includes(currentTime)
          ) {
            finalArray.push(currentTime);
            console.log('here');
            try {
              global.ga('send', 'event', {
                eventLabel: 'Play Progress',
                eventAction: `play: ${currentTime}%`,
              });
            } catch(exception) {
              console.error(exception);
            }
            
              try {
                global.AM_TrackEvent(`${currentTime}% played`, 'Audio',name, 0)
              } catch(exception) {
                console.error(exception);
              }
            
            
            
          }
    
          if (media.ended) {
            finalArray.length = 0;
            try {
              global.ga('send', 'event', {
                eventLabel: 'Play End',
                eventAction: `ended: 100%`,
              });
            } catch(exception) {
              console.error(exception);
            }
            
            try {
                global.AM_TrackEvent('100% played', 'Audio', name, 0)
              } catch(exception) {
                console.error(exception);
              }
            
            
            clearInterval(intervalRef);
          } else {
          }
        }, [500]);
      };
    
    } catch(exception) {
      console.error('Error in embedded ga.js script:');
      console.error(exception);
    }


  }



	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg.jpg`)})` }}
			>
        <div 
          className={s.headerbox}
          style={{backgroundImage: `url(${withpath(`/img/hero-overlay.png`)})` }}
        >
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
          <div className={s.hr}></div>
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>

        <div className={s.headerout}>
          <div className={s.headerman}>
            <img src={`${withpath(`/img/william.png`)}`} alt='William Cowell de Gruchy' />
            <p>William Cowell de Gruchy<br/>
            CEO, Infogrid</p>
          </div>
        </div>
			</div>



      {/* Intro */}
			<div className={s.box} data-id={1}>
				<div className={s.tbox} data-id={1}>

          <Waypoint 
            onEnter={(p) => {enter1()}} 
            onLeave={(p) => {leave1()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art1}>
              <div id='art1img'>
                <img src={`${withpath(`/img/art1.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>


          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
				</div>

			</div>


      {/* Block 2 */}
      <div className={s.box} data-id={2}>
        <div className={s.tbox} data-id={2}>

        <Waypoint 
            onEnter={(p) => {enter2()}} 
            onLeave={(p) => {leave2()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art2}>
              <div className={s.art2txt} id='art2txt'>
                <Art1 />
              </div>
              <div className={s.art2img} id='art2img'>
                <img src={`${withpath(`/img/art2.png`)}`} alt='Globe in abstract network' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={0} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />

        </div>
      </div>


      {/* Block 3 */}
			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={3}>

        <Waypoint 
            onEnter={(p) => {enter3()}} 
            onLeave={(p) => {leave3()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art3}>
              <div className={s.art3img} id='art3img'>
                <img src={`${withpath(`/img/art3.png`)}`} alt='A building angle shot from below' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />

				</div>
			</div>


      {/* Block 4: Air quality monitoring */}
			<div className={`${s.box} ${s.shaded}`} data-id={4}>
				<div className={s.tbox} data-id={4}>

        <Waypoint 
            onEnter={(p) => {enter4()}} 
            onLeave={(p) => {leave4()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art4}>
              <div className={s.art4img} id='art4img'>
                <img src={`${withpath(`/img/art4.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>

          <div className={`${s.htxt} ${s.htxtinvert}`} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={7} />

          
          <Waypoint 
            onEnter={(p) => {enterg1()}} 
            onLeave={(p) => {leaveg1()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={`${s.gbox} ${s.graph1}`}>
              <Graph1 />
            </div>
          </Waypoint>

          <Waypoint 
            onEnter={(p) => {enterg1mob()}} 
            onLeave={(p) => {leaveg1mob()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={`${s.gbox} ${s.graph1mob}`}>
              <Graph1mob />
            </div>
          </Waypoint>

				</div>
			</div>


      {/* Block 5 */}
			<div className={s.box} data-id={5}>
				<div className={s.tbox} data-id={5}>

          <Waypoint 
            onEnter={(p) => {enter5()}} 
            onLeave={(p) => {leave5()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art5}>
              <div className={s.art51} id='art51'>
                <img src={`${withpath(`/img/art5_2.png`)}`} alt='A building set in greenery, shot from below' />
              </div>
              <div className={s.art5img} id='art5img'>
                <img src={`${withpath(`/img/art5_1.png`)}`} alt='Network illustration' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
          
				</div>
			</div>


      {/* Block 6: Dashboard showing most occupied desks */}
			<div className={`${s.box} ${s.shaded}`} data-id={6}>
				<div className={s.tbox} data-id={6}>

          <div className={`${s.htxt} ${s.htxtinvert}`} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />

          <div className={s.twographs}>
            <Waypoint 
              onEnter={(p) => {enterg2()}} 
              onLeave={(p) => {leaveg2()}} 
              bottomOffset={waypercent} 
              scrollableAncestor={`window`} 
              fireOnRapidScroll={false}
            >
              <div className={`${s.gbox} ${s.graph2}`}>
                <Graph2 />
              </div>
            </Waypoint>

            <Waypoint 
              onEnter={(p) => {enterg2mob()}} 
              onLeave={(p) => {leaveg2mob()}} 
              bottomOffset={waypercent} 
              scrollableAncestor={`window`} 
              fireOnRapidScroll={false}
            >
              <div className={`${s.gbox} ${s.graph2mob}`}>
                <Graph2mob />
              </div>
            </Waypoint>

            <Waypoint 
              onEnter={(p) => {enterg3()}} 
              onLeave={(p) => {leaveg3()}} 
              bottomOffset={waypercent} 
              scrollableAncestor={`window`} 
              fireOnRapidScroll={false}
            >
              <div className={`${s.gbox} ${s.graph3}`}>
                <Graph3 />
              </div>
            </Waypoint>

            <Waypoint 
              onEnter={(p) => {enterg3mob()}} 
              onLeave={(p) => {leaveg3mob()}} 
              bottomOffset={waypercent} 
              scrollableAncestor={`window`} 
              fireOnRapidScroll={false}
            >
              <div className={`${s.gbox} ${s.graph3mob}`}>
                <Graph3mob />
              </div>
            </Waypoint>
          </div>

        </div>
      </div>


      {/* Block 7 */}
      <div className={s.box} data-id={7}>
        <div className={s.tbox} data-id={7}>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
          
        </div>
      </div>


      {/* Block 8 */}
      <div className={s.box} data-id={8}>
        <div className={s.tbox} data-id={8}>

          <AudioPlayer 
            audioUrl={withpath(data.audios[0].audioUrl)}
            title={data.audios[0].title} 
            transcript={data.audios[0].transcript} 
            transcriptTime={data.audios[0].transcriptTime}
            startTracking={!audioLoaded ? runTracking : null}
          />

          <Waypoint 
            onEnter={(p) => {enter6()}} 
            onLeave={(p) => {leave6()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art6}>
              <div className={s.art6img} id='art6img'>
                <img src={`${withpath(`/img/art6.png`)}`} alt='A illustration showing digital hand pushing a button, abstract' />
              </div>
            </div>
          </Waypoint>          

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={12} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={13} />

          <Waypoint 
            onEnter={(p) => {enter7()}} 
            onLeave={(p) => {leave7()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art7}>
              <div className={s.art7img} id='art7img'>
                <img src={`${withpath(`/img/art7.png`)}`} alt='A person inside the illuminated highrise office space' />
              </div>
            </div>
          </Waypoint>

        </div>
      </div>


      {/* Block 9 */}
      <div className={s.box} data-id={9}>
        <div className={s.tbox} data-id={9}>

          <AudioPlayer 
            audioUrl={withpath(data.audios[1].audioUrl)}
            title={data.audios[1].title} 
            transcript={data.audios[1].transcript} 
            transcriptTime={data.audios[1].transcriptTime}
            startTracking={!audioLoaded ? runTracking : null}
          />
            
          <Waypoint 
            onEnter={(p) => {enter8()}} 
            onLeave={(p) => {leave8()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art8}>
              <div className={s.art81} id='art81'>
                <img src={`${withpath(`/img/art8_2.png`)}`} alt='A new residential buildings block' />
              </div>
              <div className={s.art8img} id='art8img'>
                <img src={`${withpath(`/img/art8_1.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={14} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={15} />

        </div>
      </div>


      {/* Block 9 */}
      <div className={s.box} data-id={10}>
        <div className={s.tbox} data-id={10}>

          <div className={s.audio3}>
          	<AudioPlayer 
          	  audioUrl={withpath(data.audios[2].audioUrl)}
          	  title={data.audios[2].title} 
          	  transcript={data.audios[2].transcript} 
              transcriptTime={data.audios[2].transcriptTime}
              startTracking={!audioLoaded ? runTracking : null}
          	/>
          </div>

          <Waypoint 
            onEnter={(p) => {enter8()}} 
            onLeave={(p) => {leave8()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={`${s.art8} ${s.art8mob}`}>
              <div className={s.art81} id='art81'>
                <img src={`${withpath(`/img/art8_2.png`)}`} alt='A new residential buildings block' />
              </div>
              <div className={s.art8img} id='art8img'>
                <img src={`${withpath(`/img/art8_1.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={16} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={17} />
        
          <Waypoint 
            onEnter={(p) => {enter9()}} 
            onLeave={(p) => {leave9()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art9}>
              <div className={s.art91} id='art91'>
                <img src={`${withpath(`/img/art9_2.png`)}`} alt='A digialized workflow zoom' />
              </div>
              <div className={s.art9img} id='art9img'>
                <img src={`${withpath(`/img/art9_1.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>


        </div>
      </div>


      


		</section>
)}

export default Main
