import React, { useState, useEffect, useRef } from 'react'
import * as s from "./main.module.css"

import { Media, Player, controls } from 'react-media-player'

const { PlayPause, Progress } = controls

const AudioPlayer = ({
  audioUrl,
  title,
  transcript,
  transcriptTime,
  startTracking
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const playerObj = useRef(null);
  
  useEffect(() => {
    const audio = playerObj.current.querySelector('audio');
    audio.setAttribute('data-name', title);
  });

  return (
    <Media>
      {mediaProps => (
        <div className={s.aplayer} ref={playerObj}>
          <Player src={audioUrl} />
          <Progress className={s.aplayerProgressHidden} />
          <div className={s.aplayerMain}>
            <div className={s.aplayerHead}>
              <h4 className={s.aplayerHead}>{title}</h4>
              <p className={s.aplayerLength}>{mediaProps.duration}</p>
            </div>
            <div className={s.aplayerCtrl}>
              <div className={s.aplayerBtn} onClick={startTracking}>
                <PlayPause className={s.aplayerBtnHidden} />
                {!mediaProps.isPlaying && 
                  <svg id="Group_6973" data-name="Group 6973" xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45">
                    <path id="play" data-name="Path 112" d="M22,0A22.254,22.254,0,0,1,44,22.5,22.254,22.254,0,0,1,22,45,22.254,22.254,0,0,1,0,22.5,22.254,22.254,0,0,1,22,0Z" fill="#5f143c"/>
                    <path id="Polygon_4" data-name="Polygon 4" d="M8.673,1.219a1,1,0,0,1,1.655,0L17.94,12.438A1,1,0,0,1,17.113,14H1.887a1,1,0,0,1-.827-1.562Z" transform="translate(32 13) rotate(90)" fill="#fff"/>
                  </svg>
                }
                {mediaProps.isPlaying && 
                  <svg id="pause" data-name="Group 35030" xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45">
                    <path id="Path_112" data-name="Path 112" d="M22,0A22.254,22.254,0,0,1,44,22.5,22.254,22.254,0,0,1,22,45,22.254,22.254,0,0,1,0,22.5,22.254,22.254,0,0,1,22,0Z" fill="#5f143c"/>
                    <rect id="Rectangle_25377" data-name="Rectangle 25377" width="6" height="20" rx="1" transform="translate(14.012 12.847)" fill="#fff"/>
                    <rect id="Rectangle_25378" data-name="Rectangle 25378" width="6" height="20" rx="1" transform="translate(23.012 12.847)" fill="#fff"/>
                  </svg>
                }
              </div>
              <div className={s.aplayerProgress}>
                <div className={s.aplayerBar} style={{width: ((mediaProps.currentTime / mediaProps.duration) * 100) + '%'}}></div>
              </div>
            </div>
          </div>
          <div className={s.aplayerBtnTranscript}>
            {!isExpanded && 
              <div className={s.aplayerBtnTranscriptDefault} onClick={() => setIsExpanded(true)}>
                <svg id="Group_6969" data-name="Group 6969" xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17">
                  <g id="Group_6971" data-name="Group 6971">
                    <rect id="Rectangle_14005" data-name="Rectangle 14005" width="22" height="3" fill="#253746"/>
                    <rect id="Rectangle_14006" data-name="Rectangle 14006" width="22" height="3" transform="translate(0 7)" fill="#253746"/>
                    <rect id="Rectangle_14007" data-name="Rectangle 14007" width="16" height="3" transform="translate(0 14)" fill="#253746"/>
                  </g>
                </svg>
                Read transcript
              </div>
            }
            {isExpanded && 
              <div className={s.aplayerBtnTranscriptExpanded} onClick={() => setIsExpanded(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.678" height="17.678" viewBox="0 0 17.678 17.678">
                  <g id="Group_6969" data-name="Group 6969" transform="translate(-95.966 2.404)">
                    <g id="Group_6971" data-name="Group 6971">
                      <rect id="Rectangle_14005" data-name="Rectangle 14005" width="22" height="3" transform="translate(95.966 13.153) rotate(-45)" fill="#253746"/>
                      <rect id="Rectangle_14006" data-name="Rectangle 14006" width="22" height="3" transform="translate(98.088 -2.404) rotate(45)" fill="#253746"/>
                    </g>
                  </g>
                </svg>
                Close
              </div>
            }
            
          </div>
          <div className={`
            ${s.aplayerTranscript}
            ${isExpanded ? s.aplayerTranscriptExpanded : ''}
          `}>
            <p>
            {transcript.split(" ").map((word, i) => 
              <span 
                key={i} 
                className={`${s.aplayerTranscriptWord} ${mediaProps.currentTime > transcriptTime[i] ? s.aplayerTranscriptWordSpoken : ''}`}>
                  {word} </span>
            )}
            </p>
          </div>
        </div>
      )}
    </Media>
  )
}

export default AudioPlayer