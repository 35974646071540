// extracted by mini-css-extract-plugin
export var aplayer = "main-module--aplayer--29msI";
export var aplayerHead = "main-module--aplayerHead--1vYMX";
export var aplayerLength = "main-module--aplayerLength--1rmRU";
export var aplayerCtrl = "main-module--aplayerCtrl--2965O";
export var aplayerProgress = "main-module--aplayerProgress--1N3RW";
export var aplayerProgressHidden = "main-module--aplayerProgressHidden--1iZVv";
export var aplayerBar = "main-module--aplayerBar--1CbV-";
export var aplayerBtn = "main-module--aplayerBtn--7N8SA";
export var aplayerBtnHidden = "main-module--aplayerBtnHidden--3QOKB";
export var aplayerBtnTranscript = "main-module--aplayerBtnTranscript--QRzIS";
export var aplayerBtnTranscriptDefault = "main-module--aplayerBtnTranscriptDefault--zk8iE";
export var aplayerBtnTranscriptExpanded = "main-module--aplayerBtnTranscriptExpanded--3zsGj";
export var aplayerTranscript = "main-module--aplayerTranscript--2kqB8";
export var aplayerTranscriptExpanded = "main-module--aplayerTranscriptExpanded--3DGhD";
export var aplayerTranscriptWord = "main-module--aplayerTranscriptWord--1UWzy";
export var aplayerTranscriptWordSpoken = "main-module--aplayerTranscriptWordSpoken--2Rlrn";