// extracted by mini-css-extract-plugin
export var main = "main-module--main--1FvNJ";
export var article = "main-module--article--2jFsv";
export var headerbg = "main-module--headerbg--16AU3";
export var svgbg = "main-module--svgbg--1Wmkr";
export var headerbox = "main-module--headerbox--SRAzG";
export var hr = "main-module--hr--3ikE5";
export var headerout = "main-module--headerout--1VgDP";
export var headerman = "main-module--headerman--11Mcm";
export var hicon = "main-module--hicon--1Ovri";
export var h0 = "main-module--h0--NTeF_";
export var desc = "main-module--desc--1EfeX";
export var box = "main-module--box--2G4QO";
export var shaded = "main-module--shaded--ozY94";
export var htxt = "main-module--htxt--2apbL";
export var gbox = "main-module--gbox--hFY6d";
export var graph1 = "main-module--graph1--xIihm";
export var twographs = "main-module--twographs--3hi2H";
export var graph2 = "main-module--graph2--2kpRM";
export var graph3 = "main-module--graph3--3oJlz";
export var art8 = "main-module--art8--2W-g_";
export var graph1mob = "main-module--graph1mob--ThDOT";
export var graph2mob = "main-module--graph2mob--3HsOz";
export var graph3mob = "main-module--graph3mob--36g6N";
export var art8mob = "main-module--art8mob--3qZ-Q";
export var tbox = "main-module--tbox--2ZzNy";
export var aplayer = "main-module--aplayer--2D8HY";
export var tboxl = "main-module--tboxl--3lvVj";
export var tboxr = "main-module--tboxr--2bXVz";
export var multiart = "main-module--multiart--3txhX";
export var multiartMobile = "main-module--multiartMobile--3ece6";
export var multiartDesktop = "main-module--multiartDesktop--1Zsu3";
export var txt = "main-module--txt--1T3jE";
export var htxtinvert = "main-module--htxtinvert--1BZ0R";
export var art1 = "main-module--art1--VlUQi";
export var art2 = "main-module--art2--14cYR";
export var art3 = "main-module--art3--1Kgh6";
export var art4 = "main-module--art4--DXfg-";
export var art5 = "main-module--art5--2k8rc";
export var art6 = "main-module--art6--2E7GG";
export var art7 = "main-module--art7--3jD2U";
export var art2txt = "main-module--art2txt--1L_Yb";
export var art2img = "main-module--art2img--2KUI9";
export var art4img = "main-module--art4img--1oPOO";
export var art51 = "main-module--art51--1isew";
export var art5img = "main-module--art5img--3yeE6";
export var art81 = "main-module--art81--1BWGs";
export var art9 = "main-module--art9--2bT3l";
export var art91 = "main-module--art91--1C4cL";
export var art9img = "main-module--art9img--Bkgiw";
export var audio3 = "main-module--audio3--1LqoY";
export var legend = "main-module--legend--3PbDG";
export var axis = "main-module--axis--y6q9K";
export var source = "main-module--source--29_8s";
export var sources = "main-module--sources--2EMg7";